import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Image, { Size } from '../components/image-circle';

const FooterDiv = styled.div`
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100vw;
    height: 20px;
    text-align: center;
    font-size: 0.9em;
    color: rgba(255,255,255,0.7);
    display: flex;
    justify-content: center;
`

const Footer = () => (
    <FooterDiv>
        <span>&copy; 2021 Jason Gao &ndash; Created with</span>
        <div style={{ marginLeft: '5px', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href='https://www.gatsbyjs.org' target='_blank'>
                <Image size={Size.Tiny}>
                    <StaticQuery
                        query={graphql`
                        query {
                            placeholderImage: file(relativePath: { eq: "gatsby-icon.png" }) {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }`}
                        render={data => <GatsbyImage style={{ filter: "grayscale(75%)" }} image={data.placeholderImage.childImageSharp.gatsbyImageData} alt="Gatsby Icon" />}
                    />
                </Image>
            </a>
        </div>
    </FooterDiv>
)

export default Footer;