import React from 'react'
import styled from '@emotion/styled'

export enum Size {
    Tiny,
    Small,
    Medium,
    Large
}

const getCircleDiameter = (size: Size) => {
    switch(size) {
        case Size.Tiny:
            return 15;
        case Size.Small:
            return 50;
        case Size.Medium:
            return 100;
        case Size.Large:
            return 150;
    }
}

type ImageDivProps = {
    size: Size
    zIndex?: number
    floating?: boolean
    children: JSX.Element | string
}

const ImageDiv = styled.div`
    width: ${(props: ImageDivProps) => getCircleDiameter(props.size)}px;
    height: ${(props: ImageDivProps) => getCircleDiameter(props.size)}px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid rgba(255,255,255,0.7);
    ${(props: ImageDivProps) => props.zIndex ? `z-index: ${props.zIndex};` : ''}
    overflow: hidden;
    ${(props: ImageDivProps) => props.floating ? `@keyframes float {
        0% {
            box-shadow: 0px 5px 20px rgba(0,0,0,0.4);
            transform: translateY(0);
        }
        50% {
            box-shadow: 0px 15px 15px rgba(0,0,0,0.2);
            transform: translateY(-10px);
        }
        100% {
            box-shadow: 0px 5px 20px rgba(0,0,0,0.4);
            transform: translateY(0);
        }
    }
    animation: float 3s ease-in-out infinite;` : ''}
`

const Image = (props: ImageDivProps) => (
    <ImageDiv size={props.size} zIndex={props.zIndex} floating={props.floating}>
        {props.children}
    </ImageDiv>
)

export default Image