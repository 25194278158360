import Background, { Raised } from '../components/background'
import Header from '../components/header'
import Pages from '../enums/pages'
import Container from '../components/container'
import styled from '@emotion/styled'
import Image, { Size } from '../components/image-circle'
import React, { Component } from 'react'
import Wrapper from '../components/centered-wrapper'
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Footer from '../components/footer'
import ReactGA from '../components/ga'
import LongScroll from '../components/long-scroll'

const AboutContainer = styled.div`
    width: 70%;
    max-width: 1200px;
    border-radius: 0 20px 20px 20px;
    background-color: rgba(255,255,255,1);
    height: 60%;
    z-index: 5;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    margin-left: -100px;
    opacity: 0;
    transition: all 0.7s;
`

class AboutContainerComponent extends Component<any, { mounted: boolean }> {
    constructor(props) {
        super(props)
        this.state = {
            mounted: false
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ mounted: true }), 50);
    }

    render() {
        let style = this.state.mounted ? { marginLeft: 0, opacity: 1 } : {} 
        return (
            <AboutContainer style={style}>
                {this.props.children}
            </AboutContainer>
        )
    }
}

const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: -110px;
`
const TextContainer = styled.div`
    padding: 40px 10px 40px 0;
    color: rgba(0,0,0,0.7);
    height: 100%;
    overflow: auto;
`

const Head = styled.h1`
    font-weight: 700;
`

const About = () => (
    <Container>
        <Header page={Pages.About} />
        <Wrapper>
            <AboutContainerComponent>
                <ImageWrapper>
                    <Image size={Size.Medium}>
                        <StaticQuery
                            query={graphql`
                            query {
                                placeholderImage: file(relativePath: { eq: "profile.JPG" }) {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }`}
                            render={data => <GatsbyImage style={{ marginTop: -10 }} image={data.placeholderImage.childImageSharp.gatsbyImageData} alt="Profile Image" />}
                        />
                    </Image>
                </ImageWrapper>
                <TextContainer>
                    <LongScroll>
                        <>
                            <Head>Jason Gao</Head>
                            <div style={{ fontSize: "16px", padding: "10px 40px 10px 0" }}>
                                <p>So you want to know a little about me...</p>

                                <p>First thing you should know is that I love to travel, but that's not why you're here. Ever since a young age, I've always had a knack for learning quickly. Couple that with a curiosity for all things tech and this of course eventually led me to teach myself how to write software!
                                I started out first learning PHP, because you know where else would you start besides making yourself a wordpress site. I then made my way over to Perl after taking a few courses in Bioinformatics, and along the way I picked up a few things about Java and PL/SQL as well.
                                Most recently however, a large part of my experience is with writing Javascript for the front-end using KnockoutJs/React and back-end API development with C#/ServiceStack.</p>

                                <p>As a full stack kind of guy, I like to have a hand in just about everything. Some of my favorites include Javascript, React, C#, and dare I say even some SQL. Currently, I'm mostly interested in front-end app development using
                                React and/or Gatsby with Typescript (I think I've also jumped on the GraphQL train). Additionally, I really do enjoy using AWS' platform services for my projects whenever possible (they just make it so easy).</p>

                                <p>I've been writing software for the past 8 years or so and I'm always looking for a new challenge. I strive to learn something new everyday from anyone and everyone!</p>

                                <p>Now on to the less important details... In my spare time I love to read, listen to audio books, play sports, watch videos (Netflix, Youtube, you name it...), and just relax at home. Most of all though, when I do get time off I travel around the world as much as I can.</p>

                                <p>See where I've been by visiting my <a href="https://app.travlog.io/public/jj689">travlog</a> page.</p>
                            </div>
                        </>
                        {/* <>
                            <div>
                                Test
                            </div>
                        </>
                        <>
                            <div>
                                Test 2
                            </div>
                        </> */}
                    </LongScroll>
                </TextContainer>
            </AboutContainerComponent>
            <Background raised={Raised.Left} offset={0} />
        </Wrapper>
        <Footer />
    </Container>
)

const AboutGa = ({ location }) => <ReactGA pathname={location.pathname}><About /></ReactGA>

export default AboutGa