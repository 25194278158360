import React, { Component }from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    overflow-x: hidden;
`

const Nav = styled.div`
    min-width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const NavItem = styled.div<{ selected: boolean }>`
    width: ${props => props.selected ? '5px' : '10px'};
    height: ${props => props.selected ? '5px' : '10px'};
    opacity: ${props => props.selected ? 0.8 : 0.5 };
    border-radius: 50%;
    background-color: black;
    margin: 15px 0;
`

const LongScrollContent = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    top: 0;
    left: 0;
    padding-right: 30px;
    margin-right: -20px;
`

class ElementRef {
    ref: React.RefObject<HTMLDivElement>
    id: number;

    constructor({ id, ref }) {
        this.ref = ref
        this.id = id
    }
}

const LongScrollItem = styled.div`
    width: 100%;
    min-height: 100%;
`

class LongScrollDiv extends Component<{ addRef: (ref: React.RefObject<HTMLDivElement>, id: number) => void, id: number, children: JSX.Element }, {}> {
    ref: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.props.addRef(this.ref, this.props.id);
    }

    render() {
        return <LongScrollItem ref={this.ref}>
            {this.props.children}
        </LongScrollItem>
    }
}

class LongScroll extends Component<{ children: JSX.Element | JSX.Element[] }, { visibleItem: JSX.Element, refs: ElementRef[] }> {
    constructor(props) {
        super(props)
        this.state = {
            visibleItem: Array.isArray(props.children) ? (props.children as JSX.Element[])[0] : props.children,
            refs: []
        }
    }

    scrolling = e => {
        let sorted = this.state.refs.sort((a, b) => a.id - b.id);
        debugger;
    }

    addRef = (ref: React.RefObject<HTMLDivElement>, id: number) => {
        if (this.state.refs.some(r => r.id == id)) {
            return;
        }

        this.state.refs.push(new ElementRef({ ref, id }));
    }

    render() {
        let { children } = this.props
        let { visibleItem } = this.state

        return (
            <Container>
                <Nav>
                    {Array.isArray(children) ? (children as JSX.Element[]).map((c,i) => <NavItem key={i} selected={c == visibleItem} />) : <NavItem selected={children == visibleItem} />}
                </Nav>
                <LongScrollContent onScroll={this.scrolling}>
                    {Array.isArray(children) 
                        ? (children as JSX.Element[]).map((c,i) => <LongScrollDiv key={i} id={i} addRef={(ref, id) => this.state.refs.push(new ElementRef({ ref, id }))}>{c}</LongScrollDiv>) 
                        : <LongScrollDiv id={1} addRef={this.addRef}>{children}</LongScrollDiv>}
                </LongScrollContent>
            </Container>
        )
    }
}

export default LongScroll;